<template>
  <div>
    <b-tabs>
      <!-- english translation -->
      <b-tab active title="English">
        <b-row>
          <b-col cols="6">
            <b-card title="Header">
              <validation-observer ref="headerDataValidation">
                <b-form>
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Page Title"
                          rules="required"
                        >
                          <b-form-input
                            v-model="page.pageTitle"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Page Title"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <label>Description</label>
                      <b-form-textarea
                        v-model="page.description"
                        placeholder="Description"
                        rows="3"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card title="Banner Image">
              <b-row>
                <b-col>
                  <ComponentMediaSelector
                  :params="{ lang: null}"
                    buttonText="Select Image"
                    @mediaSelected="handleBannerImageSelect"
                    :isMultiple="false"
                  />
                </b-col>
                <b-col v-if="page.bannerImg" class="text-right">
                  <b-button
                    @click="removeBannerMedia(null)"
                    variant="flat-danger"
                    link
                    >Remove Media</b-button
                  >
                </b-col>
              </b-row>
              <b-img
                class="mt-1"
                v-if="page.bannerImg"
                fluid
                :src="page.bannerImg"
              ></b-img>
            </b-card>
          </b-col>
        </b-row>

        <b-card title="Header Blocks">
          <b-form
            ref="blocksform"
            class="repeater-form"
            @submit.prevent="repeatBlocks(null)"
          >
            <b-row
              v-for="(block, index) in page.mtdcBlock"
              :id="block.id"
              :key="block.id"
              ref="blockrow"
            >
              <b-col md="7">
                <b-form-group label="Title" label-for="food-name">
                  <b-form-input
                    v-model="page.mtdcBlock[index].title"
                    type="text"
                    placeholder="Title"
                  />
                </b-form-group>
                <b-form-group label="Description" label-for="Description">
                  <b-form-textarea
                    v-model="page.mtdcBlock[index].description"
                    type="text"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeBlockItem(null, index)"
                >
                  <span>Remove Block</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatBlocks(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.mtdcBlock.length === 0 ? "Add Blocks" : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="What we do">
          <validation-observer ref="whatWeDoValidation">
            <b-form class="mb-2">
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Page Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="page.whatWeDo.pageTitle"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Page Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label>Description</label>
                  <b-form-textarea
                    v-model="page.whatWeDo.description"
                    placeholder="Description"
                    rows="3"
                  />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

          <b-form
            ref="whatwedolistform"
            class="repeater-form"
            @submit.prevent="repeatLists(null)"
          >
            <b-row
              v-for="(list, index) in page.whatWeDo.list"
              :id="list.id"
              :key="list.id"
              ref="listrow"
            >
              <b-col md="7">
                <b-form-group label="Title" label-for="food-name">
                  <b-form-input
                    v-model="page.whatWeDo.list[index].title"
                    type="text"
                    placeholder="Title"
                  />
                </b-form-group>
                <b-form-group label="Description" label-for="Description">
                  <b-form-textarea
                    v-model="page.whatWeDo.list[index].description"
                    type="text"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center text-center">
                <b-img
                  class="mb-1"
                  v-bind="iconProps"
                  v-if="page.whatWeDo.list[index].icon"
                  :src="page.whatWeDo.list[index].icon"
                ></b-img>
                <b-button
                  v-if="page.whatWeDo.list[index].icon"
                  @click="removeWhatToDoMedia(null, index)"
                  variant="flat-danger"
                  link
                  >Remove Media</b-button
                >
                <ComponentMediaSelector
                  :params="{ index, lang: null }"
                  @mediaSelected="handleListiconSelect"
                  :isMultiple="false"
                />
              </b-col>
              <b-col md="2" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeListItem(null, index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatLists(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.mtdcBlock.length === 0 ? "Add Blocks" : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Organization Structure">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Section Title">
                <b-form-input
                  v-model="page.organizationStructure.title"
                  type="text"
                  placeholder="Title"
                />
              </b-form-group>
              <b-form-group label="Button Title">
                <b-form-input
                  v-model="page.organizationStructure.buttonTitle"
                  type="text"
                  placeholder="Button Title"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Team Members">
                <v-select
                  multiple
                  placeholder="Select Team Members"
                  v-model="page.organizationStructure.structure"
                  :reduce="
                    (team) => {
                      return {
                        team: team._id,
                        name: team.name,
                        icon: team.thumbnailImg,
                      };
                    }
                  "
                  label="name"
                  :options="teamMembers"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" v-if="page.organizationStructure.structure.length">
              <span class="d-block">Team Member Order</span>
              <draggable
                v-model="page.organizationStructure.structure"
                tag="ul"
                class="list-group list-group-flush cursor-move"
              >
                <b-list-group-item
                  v-for="(item, index) in page.organizationStructure.structure"
                  :key="index"
                  tag="li"
                >
                  <div class="d-flex align-items-center">
                    <b-avatar :src="item.icon" />
                    <div class="ml-25">
                      <b-card-text class="mb-0 font-weight-bold">
                        {{ item.name }}
                      </b-card-text>
                    </div>
                  </div>
                </b-list-group-item>
              </draggable>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="MTDC Stats">
          <b-form
            ref="mtdcStatsform"
            class="repeater-form"
            @submit.prevent="repeatStatsBlock(null)"
          >
            <b-row
              v-for="(stats, index) in page.mtdcStats"
              :id="stats.id"
              :key="stats.id"
              ref="statsrow"
            >
              <b-col md="7">
                <b-form-group label="Title" label-for="food-name">
                  <b-form-input
                    v-model="page.mtdcStats[index].title"
                    type="text"
                    placeholder="Title"
                  />
                </b-form-group>
                <b-form-group label="Description" label-for="Description">
                  <b-form-textarea
                    v-model="page.mtdcStats[index].description"
                    type="text"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeStatsItem(null, index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatStatsBlock(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.mtdcStats.length === 0 ? "Add Stats" : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
      <!-- hindi translation -->
      <b-tab title="Hindi">
        <b-row>
          <b-col cols="6">
            <b-card title="Header">
              <validation-observer ref="headerDataValidation">
                <b-form>
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Page Title"
                          rules="required"
                        >
                          <b-form-input
                            v-model="page.translations.hi.pageTitle"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Page Title"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <label>Description</label>
                      <b-form-textarea
                        v-model="page.translations.hi.description"
                        placeholder="Description"
                        rows="3"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card title="Banner Image">
              <b-row>
                <b-col>
                  <ComponentMediaSelector
                  :params="{ lang: 'hi' }"
                    buttonText="Select Image"
                    @mediaSelected="handleBannerImageSelect"
                    :isMultiple="false"
                  />
                </b-col>
                <b-col v-if="page.translations.hi.bannerImg" class="text-right">
                  <b-button
                    @click="removeBannerMedia('hi')"
                    variant="flat-danger"
                    link
                    >Remove Media</b-button
                  >
                </b-col>
              </b-row>
              <b-img
                class="mt-1"
                v-if="page.translations.hi.bannerImg"
                fluid
                :src="page.translations.hi.bannerImg"
              ></b-img>
            </b-card>
          </b-col>
        </b-row>

        <b-card title="Header Blocks">
          <b-form
            ref="blocksform"
            class="repeater-form"
            @submit.prevent="repeatBlocks('hi')"
          >
            <b-row
              v-for="(block, index) in page.translations.hi.mtdcBlock"
              :id="block.id"
              :key="block.id"
              ref="blockrow"
            >
              <b-col md="7">
                <b-form-group label="Title" label-for="food-name">
                  <b-form-input
                    v-model="page.translations.hi.mtdcBlock[index].title"
                    type="text"
                    placeholder="Title"
                  />
                </b-form-group>
                <b-form-group label="Description" label-for="Description">
                  <b-form-textarea
                    v-model="page.translations.hi.mtdcBlock[index].description"
                    type="text"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeBlockItem('hi', index)"
                >
                  <span>Remove Block</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatBlocks('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translations.hi.mtdcBlock.length === 0
                      ? "Add Blocks"
                      : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="What we do">
          <validation-observer ref="whatWeDoValidation">
            <b-form class="mb-2">
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Page Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="page.translations.hi.whatWeDo.pageTitle"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Page Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label>Description</label>
                  <b-form-textarea
                    v-model="page.translations.hi.whatWeDo.description"
                    placeholder="Description"
                    rows="3"
                  />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

          <b-form
            ref="whatwedolistform"
            class="repeater-form"
            @submit.prevent="repeatLists('hi')"
          >
            <b-row
              v-for="(list, index) in page.translations.hi.whatWeDo.list"
              :id="list.id"
              :key="list.id"
              ref="listrow"
            >
              <b-col md="7">
                <b-form-group label="Title" label-for="food-name">
                  <b-form-input
                    v-model="page.translations.hi.whatWeDo.list[index].title"
                    type="text"
                    placeholder="Title"
                  />
                </b-form-group>
                <b-form-group label="Description" label-for="Description">
                  <b-form-textarea
                    v-model="
                      page.translations.hi.whatWeDo.list[index].description
                    "
                    type="text"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center text-center">
                <b-img
                  class="mb-1"
                  v-bind="iconProps"
                  v-if="page.translations.hi.whatWeDo.list[index].icon"
                  :src="page.translations.hi.whatWeDo.list[index].icon"
                ></b-img>
                <b-button
                  v-if="page.translations.hi.whatWeDo.list[index].icon"
                  @click="removeWhatToDoMedia('hi', index)"
                  variant="flat-danger"
                  link
                  >Remove Media</b-button
                >
                <ComponentMediaSelector
                  :params="{ index, lang: 'hi' }"
                  @mediaSelected="handleListiconSelect"
                  :isMultiple="false"
                />
              </b-col>
              <b-col md="2" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeListItem('hi', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatLists('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translations.hi.mtdcBlock.length === 0
                      ? "Add Blocks"
                      : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Organization Structure">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Section Title">
                <b-form-input
                  v-model="page.translations.hi.organizationStructure.title"
                  type="text"
                  placeholder="Title"
                />
              </b-form-group>
              <b-form-group label="Button Title">
                <b-form-input
                  v-model="
                    page.translations.hi.organizationStructure.buttonTitle
                  "
                  type="text"
                  placeholder="Button Title"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Team Members">
                <v-select
                  multiple
                  placeholder="Select Team Members"
                  v-model="page.translations.hi.organizationStructure.structure"
                  :reduce="
                    (team) => {
                      return {
                        team: team._id,
                        name: team.name,
                        icon: team.thumbnailImg,
                      };
                    }
                  "
                  label="name"
                  :options="teamMembers"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              v-if="page.translations.hi.organizationStructure.structure.length"
            >
              <span class="d-block">Team Member Order</span>
              <draggable
                v-model="page.translations.hi.organizationStructure.structure"
                tag="ul"
                class="list-group list-group-flush cursor-move"
              >
                <b-list-group-item
                  v-for="(item, index) in page.translations.hi
                    .organizationStructure.structure"
                  :key="index"
                  tag="li"
                >
                  <div class="d-flex align-items-center">
                    <b-avatar :src="item.icon" />
                    <div class="ml-25">
                      <b-card-text class="mb-0 font-weight-bold">
                        {{ item.name }}
                      </b-card-text>
                    </div>
                  </div>
                </b-list-group-item>
              </draggable>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="MTDC Stats">
          <b-form
            ref="mtdcStatsform"
            class="repeater-form"
            @submit.prevent="repeatStatsBlock('hi')"
          >
            <b-row
              v-for="(stats, index) in page.translations.hi.mtdcStats"
              :id="stats.id"
              :key="stats.id"
              ref="statsrow"
            >
              <b-col md="7">
                <b-form-group label="Title" label-for="food-name">
                  <b-form-input
                    v-model="page.translations.hi.mtdcStats[index].title"
                    type="text"
                    placeholder="Title"
                  />
                </b-form-group>
                <b-form-group label="Description" label-for="Description">
                  <b-form-textarea
                    v-model="page.translations.hi.mtdcStats[index].description"
                    type="text"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeStatsItem('hi', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatStatsBlock('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translations.hi.mtdcStats.length === 0
                      ? "Add Stats"
                      : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
      <!-- marathi translation -->
      <b-tab title="Marathi">
        <b-row>
          <b-col cols="6">
            <b-card title="Header">
              <validation-observer ref="headerDataValidation">
                <b-form>
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Page Title"
                          rules="required"
                        >
                          <b-form-input
                            v-model="page.translations.mr.pageTitle"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Page Title"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <label>Description</label>
                      <b-form-textarea
                        v-model="page.translations.mr.description"
                        placeholder="Description"
                        rows="3"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card title="Banner Image">
              <b-row>
                <b-col>
                  <ComponentMediaSelector
                  :params="{ lang: 'mr' }"
                    buttonText="Select Image"
                    @mediaSelected="handleBannerImageSelect"
                    :isMultiple="false"
                  />
                </b-col>
                <b-col v-if="page.translations.mr.bannerImg" class="text-right">
                  <b-button
                    @click="removeBannerMedia('mr')"
                    variant="flat-danger"
                    link
                    >Remove Media</b-button
                  >
                </b-col>
              </b-row>
              <b-img
                class="mt-1"
                v-if="page.translations.mr.bannerImg"
                fluid
                :src="page.translations.mr.bannerImg"
              ></b-img>
            </b-card>
          </b-col>
        </b-row>

        <b-card title="Header Blocks">
          <b-form
            ref="blocksform"
            class="repeater-form"
            @submit.prevent="repeatBlocks('mr')"
          >
            <b-row
              v-for="(block, index) in page.translations.mr.mtdcBlock"
              :id="block.id"
              :key="block.id"
              ref="blockrow"
            >
              <b-col md="7">
                <b-form-group label="Title" label-for="food-name">
                  <b-form-input
                    v-model="page.translations.mr.mtdcBlock[index].title"
                    type="text"
                    placeholder="Title"
                  />
                </b-form-group>
                <b-form-group label="Description" label-for="Description">
                  <b-form-textarea
                    v-model="page.translations.mr.mtdcBlock[index].description"
                    type="text"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeBlockItem('mr', index)"
                >
                  <span>Remove Block</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatBlocks('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translations.mr.mtdcBlock.length === 0
                      ? "Add Blocks"
                      : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="What we do">
          <validation-observer ref="whatWeDoValidation">
            <b-form class="mb-2">
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Page Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="page.translations.mr.whatWeDo.pageTitle"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Page Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label>Description</label>
                  <b-form-textarea
                    v-model="page.translations.mr.whatWeDo.description"
                    placeholder="Description"
                    rows="3"
                  />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

          <b-form
            ref="whatwedolistform"
            class="repeater-form"
            @submit.prevent="repeatLists('mr')"
          >
            <b-row
              v-for="(list, index) in page.translations.mr.whatWeDo.list"
              :id="list.id"
              :key="list.id"
              ref="listrow"
            >
              <b-col md="7">
                <b-form-group label="Title" label-for="food-name">
                  <b-form-input
                    v-model="page.translations.mr.whatWeDo.list[index].title"
                    type="text"
                    placeholder="Title"
                  />
                </b-form-group>
                <b-form-group label="Description" label-for="Description">
                  <b-form-textarea
                    v-model="
                      page.translations.mr.whatWeDo.list[index].description
                    "
                    type="text"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="align-self-center text-center">
                <b-img
                  class="mb-1"
                  v-bind="iconProps"
                  v-if="page.translations.mr.whatWeDo.list[index].icon"
                  :src="page.translations.mr.whatWeDo.list[index].icon"
                ></b-img>
                <b-button
                  v-if="page.translations.mr.whatWeDo.list[index].icon"
                  @click="removeWhatToDoMedia('mr', index)"
                  variant="flat-danger"
                  link
                  >Remove Media</b-button
                >
                <ComponentMediaSelector
                  :params="{ index, lang: 'mr' }"
                  @mediaSelected="handleListiconSelect"
                  :isMultiple="false"
                />
              </b-col>
              <b-col md="2" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeListItem('mr',index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatLists('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translations.mr.mtdcBlock.length === 0
                      ? "Add Blocks"
                      : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Organization Structure">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Section Title">
                <b-form-input
                  v-model="page.translations.mr.organizationStructure.title"
                  type="text"
                  placeholder="Title"
                />
              </b-form-group>
              <b-form-group label="Button Title">
                <b-form-input
                  v-model="
                    page.translations.mr.organizationStructure.buttonTitle
                  "
                  type="text"
                  placeholder="Button Title"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Team Members">
                <v-select
                  multiple
                  placeholder="Select Team Members"
                  v-model="page.translations.mr.organizationStructure.structure"
                  :reduce="
                    (team) => {
                      return {
                        team: team._id,
                        name: team.name,
                        icon: team.thumbnailImg,
                      };
                    }
                  "
                  label="name"
                  :options="teamMembers"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              v-if="page.translations.mr.organizationStructure.structure.length"
            >
              <span class="d-block">Team Member Order</span>
              <draggable
                v-model="page.translations.mr.organizationStructure.structure"
                tag="ul"
                class="list-group list-group-flush cursor-move"
              >
                <b-list-group-item
                  v-for="(item, index) in page.translations.mr
                    .organizationStructure.structure"
                  :key="index"
                  tag="li"
                >
                  <div class="d-flex align-items-center">
                    <b-avatar :src="item.icon" />
                    <div class="ml-25">
                      <b-card-text class="mb-0 font-weight-bold">
                        {{ item.name }}
                      </b-card-text>
                    </div>
                  </div>
                </b-list-group-item>
              </draggable>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="MTDC Stats">
          <b-form
            ref="mtdcStatsform"
            class="repeater-form"
            @submit.prevent="repeatStatsBlock('mr')"
          >
            <b-row
              v-for="(stats, index) in page.translations.mr.mtdcStats"
              :id="stats.id"
              :key="stats.id"
              ref="statsrow"
            >
              <b-col md="7">
                <b-form-group label="Title" label-for="food-name">
                  <b-form-input
                    v-model="page.translations.mr.mtdcStats[index].title"
                    type="text"
                    placeholder="Title"
                  />
                </b-form-group>
                <b-form-group label="Description" label-for="Description">
                  <b-form-textarea
                    v-model="page.translations.mr.mtdcStats[index].description"
                    type="text"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeStatsItem('mr',index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatStatsBlock('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translations.mr.mtdcStats.length === 0
                      ? "Add Stats"
                      : "Add New"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
    </b-tabs>
    <b-card>
      <b-button variant="primary" @click="savePage" size="lg" block
        >Save Page</b-button
      >
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { BListGroupItem } from "bootstrap-vue";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import { getUserToken } from "@/auth/utils";

import {
  BFormInput,
  BFormGroup,
  BTabs,
  BTab,
  BFormTextarea,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BCard,
  BImg,
  BAvatar,
} from "bootstrap-vue";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import { required, email } from "@validations";
import axios from "axios";

export default {
  components: {
    BAvatar,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTabs,
    BTab,
    BFormGroup,
    BForm,
    BButton,
    BCardText,
    ComponentMediaSelector,
    BImg,
    vSelect,
    draggable,
    BListGroupItem,
    Prism,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      iconProps: {
        width: 100,
        height: 100,
      },
      teamMembers: [],
      page: {
        pageTitle: "",
        description: "",
        bannerImg: "",
        mtdcBlock: [],
        whatWeDo: {
          pageTitle: "",
          description: "",
          list: [],
        },
        organizationStructure: {
          title: "",
          buttonTitle: "",
          structure: [],
        },
        mtdcStats: [],
        translations: {
          hi: {
            pageTitle: "",
            description: "",
            bannerImg: "",
            mtdcBlock: [],
            whatWeDo: {
              title: "",
              description: "",
              list: [],
            },
            organizationStructure: {
              title: "",
              buttonTitle: "",
              structure: [],
            },
            mtdcStats: [],
          },
          mr: {
            pageTitle: "",
            description: "",
            bannerImg: "",
            mtdcBlock: [],
            whatWeDo: {
              title: "",
              description: "",
              list: [],
            },
            organizationStructure: {
              title: "",
              buttonTitle: "",
              structure: [],
            },
            mtdcStats: [],
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    getTeamMembers() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/org/team/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.teamMembers = response.data;
          console.log('team members', this.teamMembers);
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Couldn't load Team Data");
        });
    },
    handleBannerImageSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
        this.page.translations[mediaObject.params.lang].bannerImg = mediaObject.media.src;
        } else {
        this.page.bannerImg = mediaObject.media.src;
        }
      }
    },
    handleListiconSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
        this.page.translations[mediaObject.params.lang].whatWeDo.list[mediaObject.params.index].icon =
          mediaObject.media.src;
        } else {
          this.page.whatWeDo.list[mediaObject.params.index].icon =
          mediaObject.media.src;
        }
        
      }
    },
    repeatLists(lang=undefined) {
      if(lang) {
      this.page.translations[lang].whatWeDo.list.push({
        id: this.page.translations[lang].whatWeDo.list.length + 1,
        title: "",
        description: "",
        icon: "",
      });
      } else {
        this.page.whatWeDo.list.push({
        id: this.page.whatWeDo.list.length + 1,
        title: "",
        description: "",
        icon: "",
      });
      }
      
    },
    repeatBlocks(lang=undeifned) {
      if(lang) {
        this.page.translations[lang].mtdcBlock.push({
          id: this.page.translations[lang].mtdcBlock.length + 1,
          title: "",
          description: "",
      });
      }
      else {
        this.page.mtdcBlock.push({
        id: this.page.mtdcBlock.length + 1,
        title: "",
        description: "",
      });
      }
    },
    repeatStatsBlock(lang=undefined) {
      if(lang) {
this.page.translations[lang].mtdcStats.push({
        id: this.page.translations[lang].mtdcStats.length + 1,
        title: "",
        description: "",
      });
      } else {
        this.page.mtdcStats.push({
        id: this.page.mtdcStats.length + 1,
        title: "",
        description: "",
      });
      }
      
    },
    removeStatsItem(lang=undefined, index) {
      if(lang) {
      this.page.translations[lang].mtdcStats.splice(index, 1);
      } else {
      this.page.mtdcStats.splice(index, 1);
      }
    },
    removeBlockItem(lang=undefined, index) {
      if(lang) {
      this.page.translations[lang].mtdcBlock.splice(index, 1);
      } else {
      this.page.mtdcBlock.splice(index, 1);
      }
    },
    removeListItem(lang=undefined, index) {
      if(lang) {
      this.page.translations[lang].whatWeDo.list.splice(index, 1);
      } else {
      this.page.whatWeDo.list.splice(index, 1);
      }
    },
    getPage() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/pages/aboutUs/get-all`)
        .then((response) => {
          console.log(response.data)
          const {
            pageTitle,
            description,
            bannerImg,
            mtdcBlock,
            mtdcStats,
            organizationStructure,
            whatWeDo,
            translations,
          } = response.data;
          let page = {
            pageTitle: pageTitle ? pageTitle : "",
            description: description ? description : "",
            bannerImg: bannerImg ? bannerImg : "",
            mtdcBlock,
            organizationStructure: {
              title:
                organizationStructure && organizationStructure.title
                  ? organizationStructure.title
                  : "",
              buttonTitle:
                organizationStructure && organizationStructure.buttonTitle
                  ? organizationStructure.buttonTitle
                  : "",
              structure: organizationStructure && organizationStructure.structure
                ? organizationStructure.structure.map((team) => {
                    return {
                      team: team.team._id,
                      name: team.team.name,
                      icon: team.team.thumbnailImg,
                    };
                  })
                : [],
            },
            mtdcStats: mtdcStats ? mtdcStats : [],
            whatWeDo: {
              pageTitle:
                whatWeDo && whatWeDo.pageTitle ? whatWeDo.pageTitle : "",
              description:
                whatWeDo && whatWeDo.description ? whatWeDo.description : "",
              list: whatWeDo ? whatWeDo.list : [],
            },
            translations: {
              hi: {
                pageTitle: translations && translations.hi.pageTitle ? translations.hi.pageTitle : "",
            description: translations && translations.hi.description ? translations.hi.description : "",
            bannerImg: translations && translations.hi.bannerImg ? translations.hi.bannerImg : "",
            mtdcBlock : translations && translations.hi.mtdcBlock ? translations.hi.mtdcBlock : "",
            organizationStructure: {
              title:
                translations && translations.hi.organizationStructure && translations.hi.organizationStructure.title
                  ? translations.hi.organizationStructure.title
                  : "",
              buttonTitle:
                translations && translations.hi.organizationStructure && translations.hi.organizationStructure.buttonTitle
                  ? translations.hi.organizationStructure.buttonTitle
                  : "",
              structure: translations && translations.hi.organizationStructure
                && translations.hi.organizationStructure.structure ? translations.hi.organizationStructure.structure
                : [],
            },
            mtdcStats: translations.hi.mtdcStats ? translations.hi.mtdcStats : [],
            whatWeDo: {
              title:
                translations && translations.hi.whatWeDo && translations.hi.whatWeDo.title ? translations.hi.whatWeDo.title : "",
              description:
                translations && translations.hi.whatWeDo && translations.hi.whatWeDo.description ? translations.hi.whatWeDo.description : "",
              list: translations && translations.hi.whatWeDo ? translations.hi.whatWeDo.list : [],
            },
              },
              mr: {
                pageTitle: translations && translations.mr.pageTitle ? translations.mr.pageTitle : "",
            description: translations && translations.mr.description ? translations.mr.description : "",
            bannerImg: translations && translations.mr.bannerImg ? translations.mr.bannerImg : "",
            mtdcBlock : translations && translations.mr.mtdcBlock ? translations.mr.mtdcBlock : "",
            organizationStructure: {
              title:
                translations && translations.mr.organizationStructure && translations.mr.organizationStructure.title
                  ? translations.mr.organizationStructure.title
                  : "",
              buttonTitle:
                translations && translations.mr.organizationStructure && translations.mr.organizationStructure.buttonTitle
                  ? translations.mr.organizationStructure.buttonTitle
                  : "",
              structure: translations && translations.mr.organizationStructure && translations.mr.organizationStructure.structure
                ? translations.mr.organizationStructure.structure
                : [],
            },
            mtdcStats: translations.mr.mtdcStats ? translations.mr.mtdcStats : [],
            whatWeDo: {
              title:
                translations && translations.mr.whatWeDo && translations.mr.whatWeDo.title ? translations.mr.whatWeDo.title : "",
              description:
                translations && translations.mr.whatWeDo && translations.mr.whatWeDo.description ? translations.mr.whatWeDo.description : "",
              list: translations && translations.mr.whatWeDo ? translations.mr.whatWeDo.list : [],
            },
              }
            }
          };
          console.log('page', page)
          this.page = page;
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Error fetching the page data.");
        });
    },
    savePage() {
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/pages/aboutUs/save`, this.page, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.makeToast("success", "Success", "Page saved successfully!");
        })
        .catch((error) => {
          this.makeToast("danger", "Error", "Couldn't save the page!");
        });
    },
    removeBannerMedia(lang=undefined) {
      if(lang) {
      this.page.translations[lang].bannerImg = "";
      } else {
      this.page.bannerImg = "";

      }
    },
    removeWhatToDoMedia(lang=undefined, index) {
      if(lang) {
      this.page.translations[lang].whatWeDo.list[index].icon = "";
      } else {
      this.page.whatWeDo.list[index].icon = "";
      }
    },
  },
  mounted() {
    this.getPage();
    this.getTeamMembers();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>